<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Edit PT Round</h4>
                        <form @submit.prevent="updatePT">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label >PT Session</label>
                                        <multiselect v-model="ptData.pt_session" :options="options1" :multiple="false"></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label >Month</label>
                                        <multiselect v-model="ptData.month" :options="options2" :multiple="false"></multiselect>

                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label> Year</label>
                                        <input
                                                type="year"
                                                class="form-control"
                                                v-model="ptData.year" />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label >State</label>
                                        <multiselect v-model="ptData.state" :options="options4" :multiple="false"></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="ptData.pt_type === 'EID'">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Result Sample 1 </label>
                                        <multiselect required v-model="ptData.results_sample1" label="name" :options="optionResults"  :multiple="false"   >
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Result Sample 2</label>
                                        <multiselect required v-model="ptData.results_sample2" label="name" :options="optionResults"  :multiple="false" >
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Result Sample 3</label>
                                        <multiselect required v-model="ptData.results_sample3" label="name" :options="optionResults"  :multiple="false" >
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-else  style="justify-content: space-between">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label>Result Sample 1 </label>
                                        <input type="number"
                                               v-model="ptData.results_sample1"
                                               class="form-control"
                                               placeholder="Enter Result 1"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label>Result Sample 2</label>
                                        <input type="number"
                                               v-model="ptData.results_sample2"
                                               class="form-control"
                                               placeholder="Enter Result 2"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label>Result Sample 3</label>
                                        <input type="number"
                                               v-model="ptData.results_sample3"
                                               class="form-control"
                                               placeholder="Enter Result 3"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label>Result Sample 2</label>
                                        <input type="number"
                                               v-model="ptData.results_sample4"
                                               class="form-control"
                                               placeholder="Enter Result 4"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label>Result Sample 3</label>
                                        <input type="number"
                                               v-model="ptData.results_sample5"
                                               class="form-control"
                                               placeholder="Enter Result 5"
                                        />
                                    </div>
                                </div>
                            </div>
                            <!--<div class="col-md-4">
                                <div class="form-group">
                                    <label>Operator Name</label>
                                    <input type="text"
                                           v-model="ptData.operator_name"
                                           class="form-control"
                                           placeholder="Operator Name"
                                    />
                                </div>
                            </div>-->
                            <button class="btn btn-primary" type="submit">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">All admins</h4>
                            </div>

                            <div class="col-md-10 text-right">
                                <b-button v-b-modal.modal-center variant="success" class="">
                                    <i class="ri-add-line  align-middle mr-2"></i> Add site
                                </b-button>
                                <b-modal
                                        id="modal-center"
                                        centered
                                        title="Add Site(s)"
                                        title-class="font-18"
                                        ref="my-modal"
                                        hide-footer

                                >
                                    <form @submit.prevent="">
                                        <label >Select Site(s)</label>
                                        <multiselect v-model="site" :options="options3"  label="site_name" track-by="site_name" :clear-on-select="true" :multiple="true" placeholder="Select site(s)"></multiselect>
                                        <button
                                                class="btn btn-primary w-md waves-effect waves-light mt-4"
                                                type="submit"
                                                @click="hideModal"
                                        >Save</button>
                                    </form>

                                </b-modal>

                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="siteData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(actions)="{ }">
                                    <!--                                    <div class="row justify-content-end">-->
                                    <b-button v-b-tooltip.hover title="Delete" class="actionBut" variant="danger">
                                        <i class="ri-delete-bin-2-fill align-middle "></i>
                                    </b-button>

                                    <!--                                    </div>-->
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lodader__content" v-if="loader">
                        <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
   // import DatePicker from "vue2-datepicker";
    import {axios_get, axios_put} from "../../../helpers/helper";

    export default {
        page: {
            title: "Create PT Round",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, Multiselect,PageHeader },
        data() {
            return {
                month:"",
                user:null,
                loader:false,
                site:[],
                title: "Edit PT Round",
                items: [
                    {
                        text: "PT Round",
                        href: "/pt-round/"+this.$route.params.id
                    },
                    {
                        text: "Edit PT Round",
                        active: true
                    }
                ],
                optionResults:[
                    {
                        "value": 0,
                        "name":"Undetected"
                    },
                    {
                        "value": 1,
                        "name": "Detected",
                    },
                    {
                        "value": 2,
                        "name": "Invalid",
                    },
                    {
                        "value": 3,
                        "name": "",
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "created_at",
                sortDesc: false,
                fields: [
                    { key: "ptid", sortable: true },
                    { key: "site_name", sortable: false },
                    { key: "email", sortable: false },
                    { key: "region", sortable: false },
                    { key: "location", sortable: false },
                    { key: "enrollment_status", sortable: false },
                    { key: "type_facility", sortable: false },
                    { key: "telephone", sortable: false },
                    // { key: "actions", sortable: false },
                ],
                options1: [
                    "SESSION 1",
                    "SESSION 2",
                    "SESSION 3",
                    "SESSION 4",
                    "SESSION 5",
                ],
                options2: [
                    "January",
                    "Febuary",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"

                ],
                options3: [],
                options4:[
                  "PENDING",
                  "ONGOING",
                    "COMPLETED",
                ],

                value:null,
                value1: null,
                value2: null,
                //pt_session:"",
                demoMonth:"",
                ptData:null,
                siteData:null,
            };

        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.siteData.length;
            }
        },
        methods: {
            hideModal() {
                this.$refs['my-modal'].hide()
            },
            updatePT(){
                this.loader = true
                this.ptData.reference = (this.ptData.pt_session+this.ptData.month+this.ptData.year).toUpperCase()
                if(this.ptData.pt_type === 'EID'){
                    this.ptData.results_sample1=this.ptData.results_sample1.value
                    this.ptData.results_sample2=this.ptData.results_sample2.value
                    this.ptData.results_sample3=this.ptData.results_sample3.value
                }
                
                console.log(this.ptData)
                axios_put("/pt_rounds/"+this.id,{...this.ptData},
                    (d)=>{
                        console.log(d);
                        if(this.ptData.pt_type === 'EID'){
                            window.location.href = '/ptround/eid-ptrounds'
                        }else{
                            window.location.href = '/ptround/vl-ptrounds'
                        }
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                        this.loader = false
                    })

            },

        },
        mounted() {
            console.log(this.$route.params.id)
            this.id=this.$route.params.id
            console.log(this.id)
            this.user = JSON.parse(localStorage.getItem('user'))
            console.log(this.user)
            axios_get("/pt_rounds/"+this.id,{},(d)=>{
                    console.log(d);
                    this.ptData=d.ptround;
                    if(d.ptround.pt_type==='EID'){
                        this.ptData.results_sample1 = this.optionResults[this.ptData.results_sample1];
                        this.ptData.results_sample2 = this.optionResults[this.ptData.results_sample2];
                        this.ptData.results_sample3 = this.optionResults[this.ptData.results_sample3];
                    }
                    this.siteData=d.sites.map(e=>{e.region=e.region.name; e.enrollment_status= new Date(e.enrollment_status).getFullYear(); return e})
                    console.log(this.ptData);
                    console.log(this.siteData);
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );

            axios_get("/sites",{},(d)=>{
                    this.options3=d.filter(e=>{return e.region.country_id == this.user.country_id &&  e.site_category == this.ptData.pt_type})
                    console.log(this.options3)
                    //this.options2=this.siteData.map(e=>(e.site_name))
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
        }
    };
</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>